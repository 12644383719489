import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

/* ----------------------------- PUBLIC_RUNTIME ------------------------------ */
export const WEB_NAME = publicRuntimeConfig.WEB_NAME;

export const APP_ENV: any = publicRuntimeConfig.NEXT_PUBLIC_APP_ENV || 'production';
export const APP_URL: any = publicRuntimeConfig.NEXT_PUBLIC_APP_URL;
export const API_URI: any = publicRuntimeConfig.NEXT_PUBLIC_API_URL;
export const APP_IMG: any = publicRuntimeConfig.NEXT_PUBLIC_IMG_URL;

export const CLIENT_GSERVICE_URL = publicRuntimeConfig.NEXT_PUBLIC_GSERVICE_URL;

export const TRUE_HITS_URL: any = publicRuntimeConfig.NEXT_PUBLIC_TRUE_HITS_URL;
export const IZOOTO_URL: any = publicRuntimeConfig.NEXT_PUBLIC_IZOOTO_URL;

export const isProd: boolean = APP_ENV.toLowerCase() === 'production';
export const IS_NO_ROBOT_INDEX: boolean = !isProd;

export const CHECK_ADS: string = publicRuntimeConfig.CHECK_ADS;
export const SHOW_ADS: string = publicRuntimeConfig.SHOW_ADS;
export const SHOW_ANALITYCS: string = publicRuntimeConfig.SHOW_ANALITYCS;
export const DEFAULT_COUNT_VIEW: number = publicRuntimeConfig.DEFAULT_COUNT_VIEW;
export const MAP_CATEGORY_NEW_ARRAY: number = publicRuntimeConfig.MAP_CATEGORY_NEW_ARRAY || 2;

// GTM
export const GTM_ID: any = publicRuntimeConfig.NEXT_PUBLIC_GTM_ID;
// RECAPTCHA
export const RECAPTCHA_SITE_KEY: any = publicRuntimeConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';

/* ----------------------------- SERVER_RUNTIME ------------------------------ */
// RECAPTCHA
export const RECAPTCHA_SECRET_KEY: any = serverRuntimeConfig.NEXT_SERVER_RECAPTCHA_SECRET_KEY || '';

// REDIS
export const REDIS_URL: any = serverRuntimeConfig.NEXT_SERVER_REDIS_URL;
export const REDIS_PW = 'Nation#2021';
export const REDIS_PORT = 6379;

export const SHOW_COMMEMORATE = publicRuntimeConfig.SHOW_COMMEMORATE || process.env.SHOW_COMMEMORATE;

export const CONTENTS_INSTAGRAM = publicRuntimeConfig.CONTENTS_INSTAGRAM;
export const CONTENTS_TIKTOK = publicRuntimeConfig.CONTENTS_TIKTOK;
export const CONTENTS_TWITTER = publicRuntimeConfig.CONTENTS_TWITTER;

export const EMBED_ELECTION = publicRuntimeConfig.EMBED_ELECTION || process.env.EMBED_ELECTION;
export const IFRAME_ELECTION = publicRuntimeConfig.IFRAME_ELECTION || process.env.IFRAME_ELECTION;

export const EMBED_ELECTION_ON_WEB = publicRuntimeConfig.EMBED_ELECTION_ON_WEB || process.env.EMBED_ELECTION_ON_WEB;
export const ELECTION_POLL_NEXT_PUBLIC_GTM_ID = publicRuntimeConfig.ELECTION_POLL_NEXT_PUBLIC_GTM_ID;

// speech
export const NEXT_PUBLIC_URL_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_SOUND;
export const NEXT_PUBLIC_URL_PATH_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_PATH_SOUND;
export const NEXT_PUBLIC_WEB_NAME_SOUND = publicRuntimeConfig.NEXT_PUBLIC_WEB_NAME_SOUND;
export const NEXT_PUBLIC_ID_MOCK_SOUND = publicRuntimeConfig.NEXT_PUBLIC_ID_MOCK_SOUND;
export const NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND;
export const NEXT_PUBLIC_PLACEHOLDER_TIME_OUT = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TIME_OUT;
// ControlAdsHighlight3
export const NEXT_PUBLIC_CONTROL_ADS_NUMBER = publicRuntimeConfig.NEXT_PUBLIC_CONTROL_ADS_NUMBER;
export const AUTH0_BASE_URL = serverRuntimeConfig.AUTH0_BASE_URL;
export const AUTH0_ISSUER_BASE_URL = serverRuntimeConfig.AUTH0_ISSUER_BASE_URL;
export const AUTH0_CLIENT_ID = serverRuntimeConfig.AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = serverRuntimeConfig.AUTH0_CLIENT_SECRET;
export const REDIS_HOST = serverRuntimeConfig.REDIS_HOST;
export const REDIS_PASSWORD = serverRuntimeConfig.REDIS_PASSWORD;
export const REDIS_TTL = serverRuntimeConfig.REDIS_TTL || 86400;
export const NEXT_PUBLIC_LOGIN_COOKIE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_COOKIE_DATE || 1;
export const NEXT_PUBLIC_LOGIN_STATE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_STATE_DATE || 1;
export const NEXT_PUBLIC_SPEED_KEYPOINT = publicRuntimeConfig.NEXT_PUBLIC_SPEED_KEYPOINT;
