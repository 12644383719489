import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import data from 'src/redux/data';
import navSlice from 'src/redux/navSlice';
import member from 'src/services/member-auth/redux';
import address from 'src/services/member-auth/redux/address';

const store = configureStore({
  reducer: {
    data,
    member: member,
    address: address,
    navData: navSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppDispatch: () => AppDispatch = useDispatch;

export { store, useAppDispatch, useAppSelector };
