import { ProfilePageJsonLd, SiteLinksSearchBoxJsonLd } from 'next-seo';
import { useMemo } from 'react';
import { APP_URL } from 'src/constants/index';
import { IMeta } from 'src/interface/section';
import { controller } from 'src/switch-themes';
import { escapeChar } from 'src/utils/helper';

type Props = {
  meta?: IMeta;
  tagText?: string;
};

const { title, description, name } = controller;
export const HeadVideoTags = ({ meta, tagText }: Props) => {
  const { articleTitleSeo, articleDescriptionSeo } = useMemo(() => {
    const _article: IMeta = {
      ...meta,
      title: tagText ? tagText : title,
      description: tagText ? tagText : description
    };

    /* ----------------------------------- SEO ---------------------------------- */
    const articleTitleSeo: string = escapeChar(_article.title) || title;
    const articleDescriptionSeo: string = escapeChar(_article.description) || description;

    return {
      articleTitleSeo,
      articleDescriptionSeo
    };
  }, [meta, tagText]);

  return (
    <>
      {/* ---------------------------- SNIPPET : WEB_PAGE --------------------------- */}
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: `
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "${articleTitleSeo}",
              "description": "${articleDescriptionSeo}",
              "publisher": {
                "@type": "ProfilePage",
                "name": "${name.en}"
              }
            }
          `
        }}
      />
    </>
  );
};

export const BodyVideoTags = ({ meta, tagText }: Props) => {
  const { _article, tagName } = useMemo(() => {
    const _article: IMeta = {
      ...meta,
      title: tagText ? tagText : title,
      keyword: `${tagText}, tags`
    };

    /* ----------------------------------- SEO ---------------------------------- */
    const tagName: string = escapeChar(tagText) || '';

    return {
      _article,
      tagName
    };
  }, [meta, tagText]);

  const textTitle = _article.title || title;

  return (
    <>
      {/* ---------------------------- SNIPPET : WEB_SITE --------------------------- */}
      <SiteLinksSearchBoxJsonLd
        url={`${APP_URL}`}
        potentialActions={[
          {
            target: `${APP_URL}/search?q`,
            queryInput: 'search_term_string'
          }
        ]}
      />

      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <ProfilePageJsonLd
        breadcrumb={[
          { position: 1, name: 'หน้าแรก', item: `${APP_URL}` },
          {
            position: 2,
            name: `${textTitle}`,
            item: `${APP_URL}/tags/${tagName}`
          }
        ]}
      />
    </>
  );
};
