import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  result: null,
  menu: null
};
export const navSlice = createSlice({
  name: 'navData',
  initialState: initialState,
  reducers: {
    LOTTO_DATA: (state, action) => {
      state.result = action.payload;
    },
    NAV_DATA: (state, action) => {
      state.result = action.payload;
    },
    MENU_DATA: (state, action) => {
      state.menu = action.payload;
    }
  }
});
export const { LOTTO_DATA, NAV_DATA, MENU_DATA } = navSlice.actions;
export default navSlice.reducer;
