import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/scss/lg-video.scss';
import 'lightgallery/scss/lg-zoom.scss';
import 'lightgallery/scss/lightgallery.scss';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'src/assets/style/global.scss';
import 'src/assets/style/themes.scss';

import { Provider } from 'react-redux';
import { APP_IMG, GTM_ID, SHOW_ANALITYCS, TRUE_HITS_URL } from 'src/constants/index';
import { store } from 'src/redux/store';
import { controller } from 'src/switch-themes';
import { splitRote } from 'src/utils/helper';
import { SnippetsPageBody } from 'src/utils/meta';

const PageError = dynamic(import('pages/404'), { ssr: true });
const SnippetsApp = dynamic(import('src/utils/meta/snippets/app'), { ssr: true });

function MyApp({ Component, pageProps: { ...pageProps } }: AppProps | any) {
  useEffect(() => {
    if (typeof window !== 'undefined' && 'PerformanceObserver' in window) {
      new PerformanceObserver(list => {
        const latestEntry = list.getEntries().at(-1) as PerformanceEntry & { element?: Element };

        if (latestEntry?.element?.getAttribute('loading') == 'lazy') {
          console.warn('Warning: LCP element was lazy loaded', latestEntry);
        }
      }).observe({ type: 'largest-contentful-paint', buffered: true });
    }
  }, []);
  if (pageProps.statusCode >= 400) {
    return (
      <Provider store={store}>
        <PageError sessionSevId={null} errUrl={'_app'} />;
      </Provider>
    );
  }
  const router = useRouter();

  // only-themes
  // useEffect(() => {
  //   setStyleAttribute();
  // }, []);
  // only-themes

  const { facebook, twitter, theme, image, name, trueHit } = controller;
  return (
    <>
      <CacheProvider value={cache}>
        <Head>
          <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
          {/* <link rel='preconnect' href='https://www.googletagmanager.com' /> */}

          {/* <meta name='viewport' charSet='utf-8' content={`minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover`} /> */}
        </Head>
        <ThemeProvider enableSystem={false}>
          <DefaultSeo
            additionalMetaTags={[
              {
                name: 'application-name',
                content: name.en
              },
              {
                name: 'theme-color',
                content: theme?.color || '#000000'
              },
              {
                name: 'mobile-web-app-capable',
                content: 'yes'
              },
              {
                name: 'msapplication-TileColor',
                content: '#000000'
              },
              {
                name: 'msapplication-TileImage',
                content: image.logo
              },
              {
                name: 'format-detection',
                content: `telephone=yes`
              },
              {
                httpEquiv: 'x-ua-compatible',
                content: 'IE=edge; chrome=1'
              },
              {
                name: 'google',
                content: 'nositelinkssearchbox'
              },
              // {/* ---------------------------------- APPLE_META --------------------------------- */}
              {
                name: 'apple-mobile-web-app-title',
                content: name.en
              },
              {
                name: 'apple-mobile-web-app-capable',
                content: 'yes'
              },
              {
                name: 'apple-mobile-web-app-status-bar-style',
                content: theme?.appleBar || 'default'
              },
              // {/* ---------------------------------- FACEBOOK --------------------------------- */}
              {
                property: 'fb:admins',
                content: facebook?.adminId
              },
              {
                property: 'fb:pages',
                content: facebook?.pageId
              }
            ]}
            facebook={{
              appId: facebook?.appId
            }}
            twitter={{
              handle: twitter?.creator,
              site: twitter?.site,
              cardType: 'summary_large_image'
            }}
            additionalLinkTags={[
              {
                rel: 'icon',
                href: image.logo
              },
              {
                rel: 'mask-icon',
                href: image.logo,
                color: '#000000'
              },
              {
                rel: 'shortcut icon',
                type: 'image/x-icon',
                href: image.favicon
              },
              // {/* ---------------------------------- APPLE_LINK + ICON --------------------------------- */}
              // ---------------------------------- APPLE_LINK + ICON ---------------------------------
              {
                rel: 'apple-touch-startup-image',
                href: image.logoApple
              },
              {
                rel: 'apple-touch-icon',
                href: `${APP_IMG}/static/icon-58.png`,
                sizes: '58x58'
              },
              {
                rel: 'apple-touch-icon',
                href: `${APP_IMG}/static/icon-80.png`,
                sizes: '80x80'
              },
              {
                rel: 'apple-touch-icon',
                href: `${APP_IMG}/static/icon-152.png`,
                sizes: '152x152'
              },
              {
                rel: 'apple-touch-icon',
                href: `${APP_IMG}/static/icon-167.png`,
                sizes: '167x167'
              },
              {
                rel: 'apple-touch-icon',
                href: `${APP_IMG}/static/icon-180.png`,
                sizes: '180x180'
              },
              {
                rel: 'apple-touch-icon',
                href: `${APP_IMG}/static/icon-192.png`,
                sizes: '192x192'
              },
              // --------------------------------- MANIFEST -----------------------------------
              {
                rel: 'manifest',
                href: `${splitRote(router)}/static/manifest.json`
              }
            ]}
          />
          {/* -------------------------- Google Track Manager -------------------------- */}
          {SHOW_ANALITYCS && (
            <Script
              id='gtag-base'
              strategy='afterInteractive'
              dangerouslySetInnerHTML={{
                __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer', '${GTM_ID}');
                      `
              }}
            />
          )}
          <Provider store={store}>
            <Component {...pageProps} />
          </Provider>
          {/* ---------------------------- SNIPPET -------------------------- */}
          <SnippetsApp />
          <SnippetsPageBody data={pageProps} page={router.route} />
          {/* -------------------------------- truehits -------------------------------- */}
          {router.pathname === '/election/thailand' ? '' : trueHit && SHOW_ANALITYCS && <Script strategy='afterInteractive' type='text/javascript' src={`${TRUE_HITS_URL}`} />}
          {trueHit && SHOW_ANALITYCS && <Script strategy='afterInteractive' type='text/javascript' src={`${TRUE_HITS_URL}`} />}
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

export default MyApp;
