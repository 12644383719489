import { WebPageJsonLd } from 'next-seo';
import { controller } from 'src/switch-themes';

// type Props = {
//   meta?: IMeta;
// };

const { description, name } = controller;

export const HeadVideoPage = () => {
  return <></>;
};

export const BodyVideoPage = () => {
  return (
    <>
      <WebPageJsonLd description={description} id={name.en} />
    </>
  );
};
