import _ from 'lodash';
import { BreadcrumbJsonLd } from 'next-seo';
import Script from 'next/script';
import { useMemo } from 'react';
import { APP_IMG, APP_URL, SHOW_ANALITYCS, WEB_NAME } from 'src/constants/index';
import { IArticle, IMeta } from 'src/interface/section';
import { controller } from 'src/switch-themes';

export interface IMapArticle extends IArticle {
  description: string;
  keyword: string;
  imageUrl: string;
}
type Props = {
  meta?: IMeta;
  article?: IArticle;
};

// const { listShowAds } = ads;
const {
  title,
  description,
  keyword,
  image,
  isEnglish,
  contents: { instagram, twitter, tikTok, seoTitle, seoDescription }
} = controller;

export const HeadArticlesContent = (props: any) => {
  const { article } = props;

  return <>
    {/* -------------------------- SPIPPET : NewsArticle ------------------------- */}
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: ` 
                { 
                  "@context": "http://schema.org", 
                  "@type": "NewsArticle", 
                  "mainEntityOfPage": { 
                    "@type": "WebPage", 
                    "@id": "${article?.canonical || (!_.isEmpty(article?.link) && `${APP_URL}${article?.link}`) || `${APP_URL}/${article?.section?.en}/${article?.id}`}" 
                  }, 
                  "headline": "${seoTitle(article?.meta)}", 
                  "alternativeHeadline": "${seoTitle(article?.meta)}", 
                  "description": "${seoDescription(article?.meta)}", 
                  "image": "${APP_IMG}${article?.image}", 
                  "datePublished": "${article?.published_at}",
                  "dateModified": "${article?.updated_at}",
                  "author": { 
                    "@type": "Thing", 
                    "name": "${WEB_NAME}",
                    "url": "${APP_URL}"
                  }, 
                  "publisher": { 
                    "@type": "Organization", 
                    "name": "${WEB_NAME}", 
                    "logo": { 
                      "@type": "ImageObject", 
                      "url": "${image.logo}" 
                    } 
                  } 
                } 
              `
      }}
    />
  </>;
};

export const BodyArticlesContent = ({ article }: Props) => {
  if (_.isEmpty(article)) return null;

  const { setItemListElement } = useMemo(() => {
    const _article: IMapArticle = {
      ...article,
      title: article?.meta?.title || title,
      description: article?.meta?.description || description,
      keyword: article?.meta?.keyword || keyword,
      imageUrl: article?.meta?.og_social_image || image.default
    };

    /* ------------------------------- Breadcrumb ------------------------------- */
    let setItemListElement: any = '';

    if (article?.subCategory?.en !== null) {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${_article?.section?.th}`,
          item: `${APP_URL}/${_article?.section?.en}`
        },
        {
          position: 3,
          name: `${_article?.subCategory?.th}`,
          item: `${APP_URL}/${_article?.section?.en}/${_article?.subCategory?.en}`
        },
        {
          position: 4,
          name: `${_article.title}`,
          item: `${_article?.canonical || (!_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}`) || `${APP_URL}/${_article?.section?.en}/${_article?.subCategory?.en}${_article?.link}`}`
        }
      ];
    } else {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${_article?.section?.th}`,
          item: `${APP_URL}/${_article?.section?.en}`
        },
        {
          position: 3,
          name: `${_article.title}`,
          item: `${_article?.canonical || (!_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}`) || `${APP_URL}/${_article?.section?.en}${_article?.link}`}`
        }
      ];
    }

    return {
      _article,
      setItemListElement
    };
  }, [article]);

  // const textTitle = _article.title || title;
  // const textDescription = _article.description || description;

  return (
    <>
      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <BreadcrumbJsonLd itemListElements={setItemListElement} />

      {/* -------------------------- SNIPPET : NEWS_ARTICLE ------------------------- */}

      {/* <NewsArticleJsonLd
        url={_article?.canonical || `${APP_URL}${_article.link}`}
        title={`${textTitle}`}
        images={[`${APP_IMG}${_article.image}`]}
        section={`${_article.section?.th}`}
        keywords={`${_article.keyword}`}
        datePublished={`${_article.published_at}`}
        dateModified={`${_article.updated_at}`}
        authorName={`${_article.byline || name.en}`}
        author={{
          name: `${_article.byline || name.en}`,
          sameAs: _article?.canonical || `${APP_URL}${_article.link}`
        }}
        publisherName={`${_article.byline}`}
        description={`${textDescription}`}
        body={`${textDescription}`}
        dateCreated={`${_article.published_at}`}
        publisherLogo={`${image.logo}`}
        isAccessibleForFree={true}
      /> */}
      {/* <ArticleJsonLd
        useAppDir={false}
        url={_article?.canonical || `${APP_URL}${_article.link}`}
        title={`${textTitle}`}
        images={[`${APP_IMG}${_article.image}`]}
        datePublished={`${_article.published_at}`}
        dateModified={`${_article.updated_at}`}
        authorName={[
          {
            name: `${_article.byline || name.en}`,
            url: _article?.canonical || `${APP_URL}${_article.link}`
          }
        ]}
        publisherName={`${_article.byline}`}
        publisherLogo={`${image.logo}`}
        description={`${textDescription}`}
        isAccessibleForFree={true}
      /> */}
      {/* <ArticleJsonLd
        url={_article?.canonical || `${APP_URL}${_article?.link}`}
        title={`${textTitle}`}
        images={[`${APP_IMG}${_article.image}`]}
        datePublished={`${_article.published_at}`}
        dateModified={`${_article.updated_at}`}
        authorName={[
          {
            name: `${_article.byline || name.en}`,
            url: website
          }
        ]}
        publisherName={`${_article.byline || name.en}`}
        publisherLogo={`${image.logo}`}
        description={textDescription}
        isAccessibleForFree={true}
      /> */}
      {/* --------------------------- SOCIAL EMBED SCRIPT -------------------------- */}
      {instagram === 'true' && SHOW_ANALITYCS && <Script id='widget-instagram' strategy='afterInteractive' async src='//www.instagram.com/embed.js' />}
      {twitter === 'true' && SHOW_ANALITYCS && <Script id='widget-twitter' strategy='afterInteractive' async src='https://platform.twitter.com/widgets.js' />}
      {tikTok === 'true' && SHOW_ANALITYCS && <Script id='widget-tik-tok' strategy='afterInteractive' async src='https://www.tiktok.com/embed.js' />}
    </>
  );
};
