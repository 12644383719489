import { FcGoogle } from 'react-icons/fc';
import getConfig from 'next/config';
import { FaFacebook } from 'react-icons/fa';
import { BsLine } from 'react-icons/bs';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

/* -------------------------------------------------------------------------- */
/*                                    LOGIN                                   */
/* -------------------------------------------------------------------------- */

export const MEMBER_SECRET_KEY = publicRuntimeConfig.MEMBER_SECRET_KEY;
export const WEB_NAME = publicRuntimeConfig.WEB_NAME;
export const SHOW_MEMBER = publicRuntimeConfig.SHOW_MEMBER || process.env.SHOW_MEMBER;

export const NEXTAUTH_URL = serverRuntimeConfig.NEXTAUTH_URL;
export const NEXTAUTH_SECRET = serverRuntimeConfig.NEXTAUTH_SECRET;
export const MEMBER_API_URL = serverRuntimeConfig.MEMBER_API_URL;
export const MONGODB_URI = serverRuntimeConfig.MONGODB_URI;
export const APP_SECRET_KEY = serverRuntimeConfig.APP_SECRET_KEY;

/* --------------------------------- LINE --------------------------------- */
export const LINE_CLIENT_ID = serverRuntimeConfig.LINE_CLIENT_ID;
export const LINE_CLIENT_SECRET = serverRuntimeConfig.LINE_CLIENT_SECRET;

/* --------------------------------- FACEBOOK --------------------------------- */
export const FACEBOOK_CLIENT_ID = serverRuntimeConfig.FACEBOOK_CLIENT_ID;
export const FACEBOOK_CLIENT_SECRET = serverRuntimeConfig.FACEBOOK_CLIENT_SECRET;

/* --------------------------------- GOOGLE --------------------------------- */
export const GOOGLE_CLIENT_ID = serverRuntimeConfig.GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = serverRuntimeConfig.GOOGLE_CLIENT_SECRET;

/* --------------------------------- LINKEDIN --------------------------------- */
export const LINKED_IN_CLIENT_ID = serverRuntimeConfig.LINKED_IN_CLIENT_ID;
export const LINKED_IN_CLIENT_SECRET = serverRuntimeConfig.LINKED_IN_CLIENT_SECRET;

export const PROVIDERS_MEMBER = [
  {
    id: 'google',
    name: 'Google',
    type: 'oauth',
    icon: <FcGoogle id='google' size={20} />,
    signinUrl: `${NEXTAUTH_URL}/api/auth/signin/google`,
    callbackUrl: `${NEXTAUTH_URL}/api/auth/callback/google`
  },
  {
    id: 'facebook',
    name: 'Facebook',
    type: 'oauth',
    icon: <FaFacebook id='facebook' size={20} />,
    signinUrl: `${NEXTAUTH_URL}/api/auth/signin/facebook`,
    callbackUrl: `${NEXTAUTH_URL}/api/auth/callback/facebook`
  },
  {
    id: 'line',
    name: 'Line',
    type: 'oauth',
    icon: <BsLine id='line' size={20} />,
    signinUrl: `${NEXTAUTH_URL}/api/auth/signin/line`,
    callbackUrl: `${NEXTAUTH_URL}/api/auth/callback/line`
  }
];
