import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/th';
import _ from 'lodash';
import numeral from 'numeral';
// import he from 'he';
dayjs.extend(relativeTime);

const monthNames: string[] = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

export const timestamp = () => {
  return dayjs(Date.now()).format('YYYY-MM-DD HH:MM:ss');
};

export function usePageViews(_pageviews: number): string | number {
  const result = _pageviews > 999 ? numeral(+_pageviews).format('0.0 a') : _pageviews;
  return result;
}

export const convertObjPath = (_obj: any): string => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = encodeURI(`?${entries.join('&').split(',').join('=')}`);
  return itemData;
};
export const convertObjPathNoneEncode = (_obj: any): string => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = `?${entries.join('&').split(',').join('=')}`;
  return itemData;
};

export const convertArrPathLottery = (_obj: any) => {
  if (!Array.isArray(_obj.lottos) || _obj.lottos?.length === 0) return null;
  const feild = Object.keys(_obj)[0];
  const itemData = `?${feild}[]=${_obj.lottos.join(`&${feild}[]=`)}`;
  return itemData;
};

export const convertArrPath = (_obj: any, field: any): string | null => {
  let result = '';
  if (!_.isArray(_obj)) return null;

  if (_obj?.length > 0) {
    result = `?${field}[]=${_obj.join(`&${field}[]=`)}`;
  } else {
    result = `?${field}=${_obj[0]}`;
  }
  return result;
};

export function rewriteTxt(_txt: string): string {
  if (_txt.indexOf('.') !== -1) return 'news';
  switch (_txt) {
    case 'category':
    case 'rss':
      return 'news';
    case 'column':
      return 'column';
    default:
      return _txt;
  }
}

export function useDateUpdate(_date?: Date | string): string | null {
  if (!_date) return null;
  return dayjs(_date).locale('th').add(543, 'year').format('DD MMM YYYY');
}

export function useDateUpdateDataLayer(_date: Date | string, _showTime: boolean, isTranslate = false): string | null {
  if (!_date) return null;
  const _format = _showTime === true ? 'DD MMM YYYY |  HH:mm น.' : 'DD MMM YYYY';
  return isTranslate ? dayjs(_date).format('DD MMM YYYY - H:mm') : dayjs(_date).locale('th').add(543, 'year').format(_format);
}

export const numeralFormat = (countView: number): string | number | null => {
  if (countView === 0) return null;
  return countView > 999 ? numeral(countView).format('0.0a') : countView;
};

export function convertBadge(data: any, format: boolean): string {
  if (format) return `${data?.section?.th}/${data?.subCategory?.th}`;
  return data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
}

export const timeAgo = (_date?: Date | string): string | null => {
  if (!_date) return null;
  const dateNow = dayjs(_date).locale('th');
  const diffStr = dateNow.from(new Date());
  return diffStr;
};

export const getWidthDimension = () => {
  if (typeof window !== 'undefined') {
    const { innerWidth: width }: any = window;
    return {
      width: width || 0
    };
  }
};

export const escapeChar = (str: any): string | null => {
  if (!str) return null;
  return `${str}`.replace(/[\\]/g, '').replace(/["]/g, '\\$&');
};

export function useDatePublished(_date?: Date | string): string | null {
  if (!_date) return null;
  const today: Date = new Date(_date);
  const month = monthNames[today.getMonth()];
  const date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
  // const day = dateNames[today.getDay()];
  const year = today.getFullYear() + 543;
  return `${date} ${month} ${year}`;
}

export function convertBadgeEn(data: any, format: boolean): string {
  if (format) return `${data?.section?.en}/${data?.subCategory?.en}`;
  return data?.subCategory?.en ? data?.subCategory?.en : data?.section?.en;
}

export const splitRote = (_route: any, special?: boolean) => {
  if (!_route) return '';
  if (_route.route === '/404') return '';
  if (special) return '/themes-001';
  const stepI = _route.route?.split('/')[1] || _route.url?.split('/')[1] || '';
  const stepII = stepI.split('-');
  const stepIII = stepII[0] === 'themes' ? '/' + stepI : '';

  return stepIII;
};

export function convertPage(page: any) {
  const themePage = splitRote({ route: page });
  switch (page) {
    case `${themePage || '/'}`:
      return 'homepage';
    case `${themePage}/[cid]`:
      return 'category';
    case `${themePage}/[cid]/[id]`:
      return 'contents';
    case `${themePage}/climatecenter`:
      return 'category';
    case `${themePage}/climatecenter/[cid]`:
      return 'category';
    case `${themePage}/climatecenter/[cid]/[id]`:
      return 'contents';
    case `${themePage}/tags/[id]`:
      return 'tags';
    case `${themePage}/search`:
      return 'search';
    case `${themePage}/lottery/[id]`:
      return 'lotteryContents';
    case `${themePage}/lottery`:
      return 'lotteryCategory';
    case `${themePage}/video`:
      return 'videoPage';
    case `${themePage}/video/[cid]`:
      return 'videoCategory';
    case `${themePage}/video/[cid]/[id]`:
      return 'videoContents';
    case `${themePage}/galleries/[cid]`:
      return 'galleriesCategory';
    case `${themePage}/galleries/[id]`:
      return 'galleriesContents';
    case `${themePage}/galleries/tags/[id]`:
      return 'galleriesTags';
    default:
      return page;
  }
}

export function htmlToPlainText(html: any) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  return dom.body.textContent || '';
}

export function convertCategories(_txt: string): any {
  if (!_txt) return null;
  const textList = _txt?.split('/');
  const path = _txt?.split('/category');
  let res: any = [];
  if (textList?.length > 0) {
    const query = {
      category: textList?.[2] || '',
      subCategory: textList?.[3] || '',
      path: path?.[1] || ''
    };
    res = query;
  }

  return res;
}
