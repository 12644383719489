import { WebPageJsonLd } from 'next-seo';
import { controller } from 'src/switch-themes';

// type Props = {
//   meta?: IMeta;
// };

const {
  description,
  name
  // homepage: { flipNewspaper }
} = controller;

export const HeadHomepage = () => {
  return (
    <>
      {/* --------------------------------- FLIP_NEWSPAPER ---------------------------------- */}
      {/* {flipNewspaper && SHOW_ANALITYCS && <script src='//static.fliphtml5.com/web/js/plugin/LightBox/js/fliphtml5-light-box-api-min.js' />} */}
    </>
  );
};

export const BodyHomepage = () => {
  return (
    <>
      <WebPageJsonLd description={description} id={name.en} />
    </>
  );
};
