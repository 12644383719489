import { isEmpty } from 'lodash';
import { APP_IMG, APP_URL, CONTENTS_INSTAGRAM, CONTENTS_TIKTOK, CONTENTS_TWITTER, IZOOTO_URL, TRUE_HITS_URL, WEB_NAME } from 'src/constants';
import { IMeta } from 'src/interface/section';
import { escapeChar } from 'src/utils/helper';

const controller = {
  locale: 'th',
  name: { th: 'ฐานเศรษฐกิจ', en: 'thansettakij' },
  trueHit: !isEmpty(TRUE_HITS_URL),
  iZooTo: !isEmpty(IZOOTO_URL),
  isEnglish: WEB_NAME === 'nationthailand',
  website: `${APP_URL}/`,
  image: {
    headerLogo: `${APP_IMG}/cache/logo.webp`,
    footerLogo: `${APP_IMG}/cache/logo-white.webp`,
    favicon: `${APP_IMG}/static/favicon.ico`,
    logo: `${APP_IMG}/cache/logo.webp`,
    logoApple: `${APP_IMG}/cache/logo-apple-touch.webp`,
    logoSnop: `${APP_IMG}/cache/logo_snop.webp`,
    noArticle: `${APP_IMG}/cache/no-article.webp`,
    default: `${APP_IMG}/cache/default.webp`
  },
  title: 'ข่าวเศรษฐกิจ ข่าววันนี้ ข่าวหุ้น ราคาทอง การเงิน ธุรกิจ อสังหาริมทรัพย์ การตลาด | ฐานเศรษฐกิจ',
  description: 'ข่าวเศรษฐกิจ อัพเดทข่าววันนี้ ราคาทอง น้ำมัน ข่าวตลาดหุ้น การเงิน ธุรกิจ อสังหาริมทรัพย์ การตลาด เจาะลึกแบบตรงประเด็น | ฐานเศรษฐกิจ',
  keyword: 'วัคซีนโควิด, ซิโนเเวค, แอสตร้าเซนเนก้า, วัคซีนซิโนฟาร์ม, สละเงินเดือน, ล็อคดาวน์, ศบค., มติศบค., พล.อ.ประยุทธ์ จันทร์โอชา, เงินเดือนนายกรัฐมนตรี, เงินเดือนรัฐมนตรี, โควิดวันนี้',
  theme: {
    title: 'default',
    appleBar: 'default',
    color: '#ef8008'
  },
  facebook: {
    adminId: '',
    appId: '',
    pageId: '307513345840',
    pageEmbed: `<iframe title="widget-fb" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fthansettakij%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=370391120231501" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
  },
  twitter: {
    handle: 'Thansettakij',
    site: '@Thansettakij',
    creator: '@Thansettakij',
    pageEmbed: ''
  },
  socials: {
    facebook: 'https://www.facebook.com/thansettakij/',
    twitter: 'https://twitter.com/thansettakij/',
    instagram: '',
    youtube: 'https://www.youtube.com/channel/UCcTiVmhW5zC6mn71vYbDvpQ',
    line: 'https://page.line.me/thansettakij',
    spotify: 'https://open.spotify.com/user/31ymbenvkjvm43gi3ekrrhy7o65e',
    tiktok: ''
  },
  address: {
    streetAddress: 'บริษัท ฐานเศรษฐกิจ มัลติมีเดีย จํากัด 1854 ชั้น 8 ถนนเทพรัตน แขวงบางนาใต้ เขตบางนา',
    addressLocality: 'กรุงเทพฯ',
    postalCode: '10260',
    addressRegion: 'central',
    addressCountry: 'TH'
  },
  about: {
    title: 'Climate Center ศูนย์กลางคอนเทนต์ด้านการเปลี่ยนแปลงสภาพภูมิอากาศ เพื่อบรรลุเป้าหมาย Net Zero'
  },
  homepage: {
    flipNewspaper: false
  },
  contents: {
    instagram: CONTENTS_INSTAGRAM,
    tikTok: CONTENTS_TIKTOK,
    twitter: CONTENTS_TWITTER,
    seoTitle: (meta: IMeta) => escapeChar(meta?.title) || '',
    seoDescription: (meta: IMeta) => escapeChar(meta?.description) || '',
    seoKeywords: (meta: IMeta) => meta?.keyword || ''
  },
  category: {
    pathUrl: (meta: IMeta) => (meta?.subCategory?.en ? `${APP_URL}/category/${meta?.category?.en}/${meta?.subCategory?.en}` : meta?.category?.en ? `${APP_URL}/category/${meta?.category?.en}` : null),
    seoTitle: (meta: IMeta) =>
      meta?.subCategory?.th
        ? `ข่าว${meta?.category?.th || ''} รวมข่าวที่เกี่ยวข้องกับ ${meta?.subCategory?.th || ''} โดย ฐานเศรษฐกิจ`
        : meta?.category?.th
          ? `ข่าว${meta?.category?.th || ''} รวมข่าวที่เกี่ยวข้องกับ ${meta?.category?.th || ''} โดย ฐานเศรษฐกิจ`
          : null,
    seoDescription: (meta: IMeta) =>
      meta?.subCategory?.th
        ? `รวม ข่าว${meta?.category?.th || ''} ข่าวที่เกี่ยวข้องกับ ${meta?.category?.th || ''} ${meta?.subCategory?.th || ''} ข่าว${meta?.subCategory?.th || ''}วันนี้ ข่าว${meta?.subCategory?.th || ''
        }ที่น่าสนใจ ข่าว${meta?.subCategory?.th || ''}ล่าสุด จากทุกประเทศทั่วโลก โดย ฐานเศรษฐกิจ`
        : meta?.category?.th
          ? `รวม ข่าว${meta?.category?.th || ''} ข่าวที่เกี่ยวข้องกับ ${meta?.category?.th || ''} ข่าว${meta?.category?.th || ''}วันนี้ ข่าว${meta?.category?.th || ''}ที่น่าสนใจ ข่าว${meta?.category?.th || ''
          }ล่าสุด จากทุกประเทศทั่วโลก โดย ฐานเศรษฐกิจ`
          : null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  search: {
    seoTitle: (text: string) => `ผลการค้นหา ${text} รวมข่าวที่เกี่ยวกับ ${text}`,
    seoDescription: (text: string) => `ค้นหาข่าวเกี่ยวกับ ${text} เกาะติดข่าวของ ${text} วันนี้ ข่าวด่วน ${text} ทุกประเด็นร้อน ฐานเศรษฐกิจ สำนักข่าวออนไลน์ รายงานทุกข่าวด่วน ถ่ายทอดสด ทุกเหตุการณ์`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  tags: {
    seoTitle: (text: string) => `${text}`,
    seoDescription: (text: string) => `${text} รวมข่าว ${text} เกาะติดข่าว ${text} ข่าวเด่นของ ${text} ติดตาม ${text} เรื่องราวเกี่ยวกับ ${text} โดย ฐานเศรษฐกิจ`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  videoPage: {},
  videoContents: {
    instagram: CONTENTS_INSTAGRAM,
    tikTok: CONTENTS_TIKTOK,
    twitter: CONTENTS_TWITTER,
    seoTitle: (meta: IMeta) => escapeChar(meta?.title) || null,
    seoDescription: (meta: IMeta) => escapeChar(meta?.description) || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  videoCategory: {
    pathUrl: (meta: IMeta) => (meta?.subCategory?.en ? `${APP_URL}/category/${meta?.category?.en}/${meta?.subCategory?.en}` : meta?.category?.en ? `${APP_URL}/category/${meta?.category?.en}` : null),
    seoTitle: (meta: IMeta) => (meta?.subCategory?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.subCategory.th}` : meta?.category?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.category.th}` : null),
    seoDescription: (meta: IMeta) => meta?.description || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  videoTags: {
    seoTitle: (text: string) => `${text} รวมวิดีโอที่เกี่ยวกับ ${text}`,
    seoDescription: (text: string) => `รวมวิดีโอ ${text} เกาะติดข่าวของ ${text} ข่าวด่วนของ ${text} ที่คุณสนใจ ติดตามเรื่อง ${text}`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  galleriesContents: {
    seoTitle: (meta: IMeta) => escapeChar(meta?.title) || null,
    seoDescription: (meta: IMeta) => escapeChar(meta?.description) || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  galleriesCategory: {
    pathUrl: (meta: IMeta) => (meta?.subCategory?.en ? `${APP_URL}/category/${meta?.category?.en}/${meta?.subCategory?.en}` : meta?.category?.en ? `${APP_URL}/category/${meta?.category?.en}` : null),
    seoTitle: (meta: IMeta) => (meta?.subCategory?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.subCategory.th}` : meta?.category?.th ? `หมวดหมู่ที่เกี่ยวข้องกับ ${meta.category.th}` : null),
    seoDescription: (meta: IMeta) => meta?.description || null,
    seoKeywords: (meta: IMeta) => meta?.keyword || null
  },
  galleriesTags: {
    seoTitle: (text: string) => `${text} รวมแกลลอรี่ที่เกี่ยวกับ ${text}`,
    seoDescription: (text: string) => `รวมแกลลอรี่ ${text} เกาะติดข่าวของ ${text} ข่าวด่วนของ ${text} ที่คุณสนใจ ติดตามเรื่อง ${text}`,
    seoKeywords: (text: string) => `${text}, tags`
  },
  lotteryContents: {
    pathUrl: (date: string) => `${APP_URL}/lottery/${date}`,
    seoTitle: (meta: IMeta, date: string) => (meta?.title ? escapeChar(meta?.title) : `ตรวจสลากกินแบ่งรัฐบาล ตรวจหวย ${date} งวดล่าสุด`),
    seoDescription: (meta: IMeta, date: string) =>
      meta?.description ? escapeChar(meta?.description) : `ตรวจสลากกินแบ่งรัฐบาล ตรวจหวย ${date} ตรวจลอตเตอรี่ หวยรัฐบาล หวยวันนี้ ผลสลากกินแบ่งรัฐบาล งวดล่าสุด โดย ฐานเศรษฐกิจ`,
    seoKeywords: (meta: IMeta, date: string) => meta?.keyword || `หวย, ตรวจหวย, ผลสลากกินแบ่งรัฐบาล, ตรวจหวย ${date}`
  },
  lotteryCategory: {
    pathUrl: () => `${APP_URL}/lottery`,
    seoTitle: (meta: IMeta) => (meta?.title ? escapeChar(meta?.title) : `ตรวจสลากกินแบ่งรัฐบาล ตรวจหวย หวยรัฐบาล หวยวันนี้`),
    seoDescription: (meta: IMeta) =>
      meta?.description
        ? escapeChar(meta?.description)
        : 'ตรวจสลากกินแบ่งรัฐบาล หวยรัฐบาล ตรวจหวย ผลสลากกินแบ่งรัฐบาล ตรวจลอตเตอรี่ หวยวันนี้ งวดล่าสุด ติดตามผลสลากกินแบ่งรัฐบาลงวดนี้ โดย ฐานเศรษฐกิจ',
    seoKeywords: (meta: IMeta) => meta?.keyword || `หวย, ตรวจหวย, ผลสลากกินแบ่งรัฐบาล, ตรวจหวย รวมหวยทุกงวด โดย ฐานเศรษฐกิจ`
  }
};

export default { ...controller };
