import _ from 'lodash';
import { BreadcrumbJsonLd, NewsArticleJsonLd } from 'next-seo';
import Script from 'next/script';
import { useMemo } from 'react';
import { APP_IMG, APP_URL, SHOW_ANALITYCS } from 'src/constants/index';
import { IArticle, IMeta } from 'src/interface/section';
import { controller } from 'src/switch-themes';

export interface IMapArticle extends IArticle {
  description: string;
  keyword: string;
  imageUrl: string;
}
type Props = {
  meta?: IMeta;
  article?: IArticle;
};

// const { listShowAds } = ads;
const {
  title,
  description,
  keyword,
  image,
  name,
  isEnglish,
  contents: { instagram, twitter, tikTok }
} = controller;

export const HeadGalleriesContent = () => {
  return <></>;
};

export const BodyGalleriesContent = ({ article }: Props) => {
  if (_.isEmpty(article)) return null;
  const { _article, setItemListElement } = useMemo(() => {
    const _article: IMapArticle = {
      ...article,
      title: article?.meta?.title || title,
      description: article?.meta?.description || description,
      keyword: article?.meta?.keyword || keyword,
      imageUrl: article?.meta?.og_social_image || image.default
    };

    /* ------------------------------- Breadcrumb ------------------------------- */
    let setItemListElement: any = '';

    if (article?.subCategory?.en !== null) {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${_article?.section?.th}`,
          item: `${APP_URL}/${_article?.section?.en}`
        },
        {
          position: 3,
          name: `${_article?.subCategory?.th}`,
          item: `${APP_URL}/${_article?.section?.en}/${_article?.subCategory?.en}`
        },
        {
          position: 4,
          name: `${_article.title}`,
          item: `${_article?.canonical || (!_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}`) || `${APP_URL}/${_article?.section?.en}/${_article?.subCategory?.en}${_article?.link}`}`
        }
      ];
    } else {
      setItemListElement = [
        {
          position: 1,
          name: isEnglish ? 'Home' : 'หน้าแรก',
          item: `${APP_URL}`
        },
        {
          position: 2,
          name: `${_article?.section?.th}`,
          item: `${APP_URL}/${_article?.section?.en}`
        },
        {
          position: 3,
          name: `${_article.title}`,
          item: `${_article?.canonical || (!_.isEmpty(_article?.link) && `${APP_URL}${_article?.link}`) || `${APP_URL}/galleries/${_article?.id}`}`
        }
      ];
    }

    return {
      _article,
      setItemListElement
    };
  }, [article]);

  const textTitle = _article.title || title;
  const textDescription = _article.description || description;

  return (
    <>
      {/* ------------------------ SNIPPET : BREADCRUMB_LIST ------------------------ */}
      <BreadcrumbJsonLd itemListElements={setItemListElement} />

      <NewsArticleJsonLd
        url={_article?.canonical || (!_.isEmpty(_article.link) && `${APP_URL}${_article.link}`) || `${APP_URL}/galleries/${_article?.id}`}
        title={`${textTitle}`}
        images={[`${APP_IMG}${_article.image}`]}
        section={`${_article.section?.th}`}
        keywords={`${_article.keyword}`}
        datePublished={`${_article.published_at}`}
        dateModified={`${_article.updated_at}`}
        authorName={`${_article.byline || name.th}`}
        publisherName={`${_article.byline}`}
        description={`${textDescription}`}
        body={`${textDescription}`}
        dateCreated={`${_article.published_at}`}
        publisherLogo={`${image.logo}`}
        isAccessibleForFree={true}
      />
      {/* --------------------------- SOCIAL EMBED SCRIPT -------------------------- */}
      {instagram === 'true' && SHOW_ANALITYCS && <Script id='widget-instagram' strategy='afterInteractive' async src='//www.instagram.com/embed.js' />}
      {twitter === 'true' && SHOW_ANALITYCS && <Script id='widget-twitter' strategy='afterInteractive' async src='https://platform.twitter.com/widgets.js' />}
      {tikTok === 'true' && SHOW_ANALITYCS && <Script id='widget-tik-tok' strategy='afterInteractive' async src='https://www.tiktok.com/embed.js' />}
    </>
  );
};
